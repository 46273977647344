<script setup lang='ts'>
import { useQuery } from 'vue-query'
import { useAnnouncements } from '~~/composables/api/useAnnouncements'
import { useAlertStore } from '~/stores/alert'
import { useAuthStore } from '~/stores/auth'
import {
  SettingIdByDescription, useSettingsStore,
} from '~/stores/settings'
import { useAdminSettings } from '~/composables/api/useAdminSettings'

const props = defineProps<{
  opened: boolean
}>()

defineEmits<{
  (event: 'close'): void
}>()

const route = useRoute()
const { getAll } = useAnnouncements()
const alertStore = useAlertStore()
const authStore = useAuthStore()
const { captureException } = useSentry()
const { getAll: getAllSettings } = useAdminSettings()
const settingsStore = useSettingsStore()

const settings = computed(() => settingsStore.settings)
const user = computed(() => authStore.user)

const { data: settingsArray, isFetched: settingsLoaded, refetch: fetchSettings } = useQuery({
  queryKey: ['adminSettings'],
  queryFn: () => {
    try {
      return getAllSettings()
    }
    catch (e) {
      console.error(e)
      captureException(e)
      return []
    }
  }, // getAllSettings().then(a => settingsArrayToRecord(a)),
  initialData: [],
})

if (!settingsLoaded.value)
  fetchSettings.value()

watch(settingsArray, (a) => {
  settingsStore.setSettings(a)
})

const { data: announcements, refetch: refetchAnnouncements } = useQuery({
  queryKey: ['announcements'],
  queryFn: getAll,
  throwOnError: (e: any) => {
    console.error(e)
    captureException(e)
    return false
  },
  initialData: [],
})

watch(() => user.value?.id, (newId, oldId) => {
  if (newId && newId !== oldId && refetchAnnouncements.value) {
    console.log('Refetching announcements after auth state change...')
    refetchAnnouncements.value()
  }
})

const links = computed(() => {
  switch (route.meta.spaceName) {
    case 'dashboard': {
      return [
        {
          title: 'My Team',
          url: '/my-team',
        },
        {
          title: 'Rules',
          url: '/rules',
        },
        {
          title: 'Schedule',
          url: '/schedule',
        },
        {
          title: 'Auction',
          url: settings.value[SettingIdByDescription.EnableAuctionItems]?.status ? '/auction' : undefined,
        },
        {

          title: 'Leaderboard',
          url: '/leaderboard',
        },
        {
          title: 'Raffle',
          url: settings.value[SettingIdByDescription.EnableRaffles]?.status ? '/raffles' : undefined,
        },
        {
          title: 'Announcements',
          url: '/announcements',
          count: announcements.value?.filter(item => item.published)?.length ?? 0,
        },
      ]
    }

    case 'admin': {
      return [
        {
          title: 'Dashboard',
          url: '/admin/dashboard',
        },
        {
          title: 'Waitlist',
          url: '/admin/waitlist',
        },
        {
          title: 'Invites',
          url: '/admin/invites',
        },
        {
          title: 'Payments',
          url: '/admin/payments',
        },
        {
          title: 'Teams',
          url: '/admin/teams',
        },
        {
          title: 'Anglers',
          url: '/admin/anglers',
        },
        {
          title: 'Announcements',
          url: '/admin/announcements',
        },
        {
          title: 'Auction',
          url: '/admin/auction',
        },
        {
          title: 'Raffles',
          url: '/admin/raffles',
        },
        {
          title: 'Raffles Table',
          url: '/admin/raffles-table',
        },
        {
          title: 'Judges',
          url: '/admin/judges',
        },
        {
          title: 'Scores',
          url: '/admin/scores',
        },
        {
          title: 'Random Winners',
          url: '/admin/random-winners',
        },
        {
          title: 'Leaderboard',
          url: '/admin/leaderboard',
          live: true,
        },
        // {
        //   title: 'Photos',
        //   url: '/admin/photos',
        // },
        {
          title: 'Guests',
          url: '/admin/guests',
        },
        {
          title: 'Settings',
          url: '/admin/settings',
        },
      ]
    }

    case 'judge': {
      return [
        {
          title: 'Anglers',
          url: '/judges/anglers',
        },
        {
          title: 'Scoring',
          url: '/judges/scoring',
        },
        {
          title: 'Raffles',
          url: '/judges/raffles',
        },
        {
          title: 'Guests',
          url: '/judges/guests',
        },
      ]
    }

    default: {
      return []
    }
  }
})

const style = computed(() => ({
  wrapper: [
    'lg-max:theme-neutral-light dark:lg-max:theme-green-light lg-max:bb lg-max:rounded-2xl lg-max:shadow-lg lg-max:overflow-y-auto lg-max:max-h-[24rem]',
    'nav-wrapper overscroll-none absolute top-full mt-1 bg-t-bg left-5 right-5 justify-center lg:pointer-events-none z-[10]',
    'lg:bottom-0 lg:mt-0 lg:left-[240px] lg:bg-transparent lg:right-[240px] lg:top-0 lg:flex lg:items-center',
    {
      'lg-max:hidden': !props.opened,
    },
  ],
  links: [
    'lg-max:w-full',
    'pointer-events-auto gap-2 items-center grid mx-auto whitespace-nowrap overflow-x-auto p-2 w-full no-scrollbar',
    'lg:flex lg:h-full lg:px-10 lg:w-auto lg:gap-7 lg:py-0 lg:justify-start',
  ],
  link: (hasUrl: boolean) => [
    'lg-max:w-full',
    'group pointer-events-auto p-0.5 bg-transparent rounded-[0.875rem] transition-colors relative text-t-text-light',
    {
      'hover:text-t-text': hasUrl,
    },
  ],
  linkActive: 'is-active theme-coral !bg-t-bg !text-t-text',
  linkInner: 'flex items-center justify-center border border-t-border lg:border-transparent group-[.is-active]:border-t-border lg-max:px-4 lg-max:py-2 lg:group-[.is-active]:px-4 lg:group-[.is-active]:py-2 rounded-xl transition-[padding]',
  title: 'text-xl leading-normal heading',
  soon: 'text-base heading opacity-50 ml-1',
  sideGradient: 'hidden lg:block absolute top-0 bottom-0 w-10 from-bb to-bb/0',
}))
</script>

<template>
  <div v-if="links.length" :class="style.wrapper">
    <div :class="style.links">
      <NuxtLink
        v-for="link in links"
        :key="link.title"
        :disabled="!link.url"
        :class="style.link(!!link.url)"
        :to="link.url"
        :active-class="style.linkActive"
        @click="$emit('close')"
      >
        <div :class="style.linkInner">
          <CommonIndicator v-if="link.count" :value="link.count" class="theme-coral" border />
          <span :class="style.title">
            {{ link.title }}
          </span>
          <span v-if="!link.url" :class="style.soon">Soon</span>
          <span v-if="link.live" :class="style.soon">Live</span>
        </div>
      </NuxtLink>
      <div :class="style.sideGradient" class="left-0 bg-gradient-to-r" />
      <div :class="style.sideGradient" class="right-0 bg-gradient-to-l" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav-wrapper {
  -webkit-overflow-scrolling: touch;
}
</style>
